<template>
    <v-container>
        <v-card class="mt-16 pa-5">
            <v-card-title class="font-weight-bold">
                MSN Content Guidelines
            </v-card-title>

            <v-row class="align-center justify-center">
                <v-col cols="11" class="pa-8 pt-4">
                    <p>
                        Here are some guidelines to help you get your own
                        content out on MSN.
                    </p>

                    <div class="mb-4">
                        <h3>1. Tone and Structure</h3>
                        <ul class="ml-2 mt-1">
                            <li>
                                <b>Tone:</b>
                                Newsworthy, neutral, and professional. Avoid
                                sales-driven or overly promotional language.
                            </li>
                            <li>
                                <b>Format:</b>
                                Follow a traditional branded news article
                                structure:
                                <ul>
                                    <li>
                                        Clear, concise headline featuring the
                                        company name and the major news impact
                                        of the content.
                                    </li>
                                    <li>
                                        Introductory paragraph that highlights
                                        the broader relevance of the
                                        announcement.
                                    </li>
                                    <li>
                                        Supporting paragraphs that expand on the
                                        impact, including a boilerplate
                                        paragraph about the company.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <v-divider />
                    <div class="my-4">
                        <h3>2. Headline Requirements</h3>
                        <ul class="ml-2 mb-2 mt-1">
                            <li>Include the business name in the headline.</li>
                            <li>
                                Use a newsworthy format that summarizes the key
                                announcement and its broader relevance.
                            </li>
                            <li>
                                Avoid non-specific, overly general, or
                                overly-promotional headlines.
                            </li>
                        </ul>
                        <h4 class="font-weight-bold">
                            Examples of Effective Headlines
                        </h4>
                        <ul class="ml-2 mb-2 mt-1 font-italic">
                            <li>
                                Local Tech Firm ABC Launches AI-Powered Tool,
                                Boosting SME Productivity by 30%
                            </li>
                            <li>
                                XYZManufacturing Adopts IoT, Sees 25% Increase
                                in Supply Chain Efficiency
                            </li>
                            <li>
                                Smith & Co. Law Firm Partners with Tech Giant to
                                Offer AI-Driven Legal Services
                            </li>
                            <li>
                                Green Solutions Inc. Debuts Eco-Friendly Product
                                Line, Projects 50% Revenue Growth
                            </li>
                        </ul>
                        <h4 class="font-weight-bold">
                            Examples of Unacceptable Headlines
                        </h4>
                        <ul class="ml-2 mb-2 mt-1 font-italic">
                            <li>
                                Designing Sustainable Landscapes: 3 Key
                                Eco-Friendly Gardening Trends for 2025
                                <br />
                                (Too generic, lacks a specific announcement or
                                company name.)
                            </li>
                            <li>
                                Mark & Sons Revolutionizes Plumbing With
                                Outstanding New Drain Cleaning Tools!
                                <br />
                                (Too promotional)
                            </li>
                        </ul>
                    </div>
                    <v-divider />
                    <div class="my-4">
                        <h3>3. Broader Relevance</h3>
                        <p class="mt-1">
                            Clearly and promptly demonstrate how the
                            announcement ties into broader industry trends such
                            as:
                        </p>
                        <ul class="ml-2 mb-2">
                            <li>AI and automation.</li>
                            <li>
                                New technology or new applications of existing
                                technology.
                            </li>
                            <li class="font-weight-bold">
                                Environmental responsibility and sustainability.
                            </li>
                            <li class="font-weight-bold">
                                Social initiatives and responsible business
                                practices.
                            </li>
                        </ul>
                        <p>
                            Emphasize national, state, or international impact.
                        </p>
                        <p>
                            If you’re a local business with a more localized
                            impact, you’ll need to highlight how your business
                            aligns with broader industry trends to make your
                            content more relevant on a larger scale.
                        </p>
                        <p>Focus on any of the following areas:</p>
                        <ul class="mb-2">
                            <li>
                                <b>Tech Integration:</b>
                                Show how your use of AI, automation, or other
                                technologies aligns with current industry
                                shifts.
                            </li>
                            <li>
                                <b>Digital Transformation:</b>
                                Demonstrate how your company’s digital
                                implementation can serve as a model for other
                                businesses regionally or nationally.
                            </li>
                            <li>
                                <b>Data-Driven Insights:</b>
                                Share valuable data or trends your business has
                                identified, relevant to your industry.
                            </li>
                            <li>
                                <b>Industry Disruption:</b>
                                Highlight how your innovations are reshaping
                                industry practices and influencing competitors.
                            </li>
                            <li>
                                <b>Sustainability Initiatives:</b>
                                Emphasize ESG efforts that align with broader
                                environmental, social, and governance standards
                                – this is a particularly favorable area of focus
                                so it’s recommended to consider any potential
                                ESG relevance for your content.
                            </li>
                        </ul>
                        <p class="font-weight-bold">
                            Regardless of the area of focus, if you can tie your
                            content in with any current events or trends, do it
                            – it will increase your chances of approval on MSN.
                        </p>
                    </div>
                    <v-divider />
                    <div class="my-4">
                        <h3>4. Examples of Acceptable Content Topics</h3>
                        <ul class="ml-2 mb-2 mt-1">
                            <li>
                                New product launches with measurable industry
                                impact
                                <i>
                                    (e.g. “Local News Outlet ABC Media Launches
                                    Real-Time Financial App”).
                                </i>
                            </li>
                            <li>
                                Partnerships or acquisitions that indicate
                                industry evolution
                                <i>
                                    (e.g. “MNORobotics Acquires AI Startup, Aims
                                    to Revolutionize Industrial Automation”).
                                </i>
                            </li>
                            <li>
                                Innovations aligning with sustainability or
                                technological advancement
                                <i>
                                    (e.g. “Regional Bank JKL Introduces
                                    Blockchain-Based Payments”).
                                </i>
                            </li>
                            <li>
                                Process updates with a focus on sustainable
                                development
                                <i>
                                    (e.g. ABC Construction Adopts Carbon-Neutral
                                    Cement, Reduces Emissions).
                                </i>
                            </li>
                            <li>
                                Service updates aligning with community
                                development initiatives
                                <i>
                                    (e.g. BrightFuture Foods Launches Community
                                    Farming Initiative, Supports Local
                                    Families).
                                </i>
                            </li>
                            <li>
                                Service expansions with an emphasis on
                                increasing service accessibility
                                <i>
                                    (e.g. HealthFirst Clinics Expands Rural
                                    Healthcare Access, Serving 9,000 New
                                    Patients In Lake County, OR).
                                </i>
                            </li>
                        </ul>
                    </div>
                    <v-divider />
                    <div class="my-4">
                        <h3>5. Prohibited Topics</h3>
                        <p class="mt-1">
                            MSN does not accept content related to the
                            following:
                        </p>
                        <ol class="ml-2">
                            <li>
                                <b>Illegal Activities or Products</b>
                                <ul>
                                    <li>
                                        Content promoting or describing illegal
                                        activities.
                                    </li>
                                    <li>
                                        Announcements related to illegal
                                        products or services.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <b>
                                    Hate Speech, Discriminatory Content,
                                    Personal Attacks, or Defamatory Statements
                                </b>
                                <ul>
                                    <li>
                                        Content that promotes hate, violence, or
                                        attacks against individuals or groups.
                                    </li>
                                    <li>
                                        Discriminatory policies or practices.
                                    </li>
                                    <li>
                                        Defamatory statements targeting
                                        competitors or others.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <b>Unverified or Sensationalized Claims</b>
                                <ul>
                                    <li>
                                        Extraordinary claims without substantial
                                        evidence.
                                    </li>
                                    <li>
                                        Exaggerated or misleading statements
                                        about products, services, or trends.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <b>Explicit or Gratuitous Violence</b>
                                <ul>
                                    <li>
                                        Detailed descriptions of violent acts.
                                    </li>
                                    <li>
                                        Announcements promoting or glorifying
                                        violence.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <b>Adult Content or Excessive Profanity</b>
                                <ul>
                                    <li>Sexually explicit material.</li>
                                    <li>
                                        Content with excessive or unnecessary
                                        profanity.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <b>
                                    Political Campaigning or Partisan Content
                                    <ul>
                                        <li>
                                            Appeals for votes or financial
                                            support for political candidates.
                                        </li>
                                        <li>
                                            Biased political commentary
                                            disguised as news.
                                        </li>
                                    </ul>
                                </b>
                            </li>
                            <li>
                                <b>Pseudoscience or Misinformation</b>
                                <ul>
                                    <li>
                                        Content promoting scientifically
                                        discredited theories.
                                    </li>
                                    <li>
                                        Announcements based on misleading or
                                        false information.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <b>Private or Confidential Information</b>
                                <ul>
                                    <li>
                                        Releases containing personal data
                                        without consent.
                                    </li>
                                    <li>
                                        Announcements revealing trade secrets or
                                        confidential business information.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <b>
                                    Plagiarized or Copyright-Infringing Content
                                </b>
                                <ul>
                                    <li>
                                        Press releases or announcements copied
                                        from other sources without permission.
                                    </li>
                                    <li>
                                        Unauthorized use of trademarked terms,
                                        logos, or proprietary information.
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                    <v-divider />
                    <div class="my-4">
                        <h3>6. Key Requirements Checklist</h3>
                        <div class="px-4">
                            <div class="d-flex align-center mb-n2">
                                <v-checkbox disabled />
                                <h3 class="font-weight-light">
                                    Content Focus & Relevance
                                </h3>
                            </div>
                            <ul class="ml-2">
                                <li>
                                    Does your news relate to business,
                                    technology, finance, digital transformation,
                                    ESG initiatives, or marketing?
                                </li>
                                <li>
                                    Have you framed your story to reflect
                                    broader trends or industry shifts?
                                </li>
                            </ul>
                            <v-divider class="mx-4 mt-4" />
                            <div class="d-flex align-center mb-n2">
                                <v-checkbox disabled />
                                <h3 class="font-weight-light">
                                    Newsworthiness
                                </h3>
                            </div>
                            <ul class="ml-2">
                                <li>
                                    Does your content have state-level,
                                    national, or international impact?
                                </li>
                                <li>
                                    Have you highlighted the scope of impact,
                                    trend indicators, innovation factor, and/or
                                    economic implications of your news?
                                </li>
                                <li>
                                    If it’s a local story, have you identified
                                    broader relevance or significance?
                                </li>
                            </ul>
                            <v-divider class="mx-4 mt-4" />
                            <div class="d-flex align-center mb-n2">
                                <v-checkbox disabled />
                                <h3 class="font-weight-light">
                                    Neutral Tone & Objectivity
                                </h3>
                            </div>
                            <ul class="ml-2">
                                <li>
                                    Is the press release written in a neutral
                                    tone, free from promotional language or
                                    sales pitches?
                                </li>
                                <li>
                                    Are opinions clearly labeled, and have you
                                    provided balanced viewpoints?
                                </li>
                            </ul>
                            <v-divider class="mx-4 mt-4" />
                            <div class="d-flex align-center mb-n2">
                                <v-checkbox disabled />
                                <h3 class="font-weight-light">
                                    Factual Accuracy
                                </h3>
                            </div>
                            <ul class="ml-2">
                                <li>
                                    Are all claims and statistics properly
                                    sourced and verifiable?
                                </li>
                                <li>
                                    Have you included links to reputable sources
                                    for key data points?
                                </li>
                            </ul>
                            <v-divider class="mx-4 mt-4" />
                            <div class="d-flex align-center mb-n2">
                                <v-checkbox disabled />
                                <h3 class="font-weight-light">
                                    Context & Background
                                </h3>
                            </div>
                            <ul class="ml-2">
                                <li>
                                    Have you provided enough context to connect
                                    your news to larger industry trends?
                                </li>
                                <li>
                                    Does your announcement explain its
                                    significance within the broader market or
                                    industry?
                                </li>
                            </ul>
                            <v-divider class="mx-4 mt-4" />
                            <div class="d-flex align-center mb-n2">
                                <v-checkbox disabled />
                                <h3 class="font-weight-light">Timeliness</h3>
                            </div>
                            <ul class="ml-2">
                                <li>
                                    Is your news timely, focusing on recent
                                    developments or upcoming announcements?
                                </li>
                                <li>
                                    If referencing past events, have you clearly
                                    explained their relevance to current news?
                                </li>
                            </ul>
                            <v-divider class="mx-4 mt-4" />
                            <div class="d-flex align-center mb-n2">
                                <v-checkbox disabled />
                                <h3 class="font-weight-light">
                                    Clarity & Accessibility
                                </h3>
                            </div>
                            <ul class="ml-2">
                                <li>
                                    Is your language clear, free from jargon,
                                    and accessible to both experts and a broader
                                    audience?
                                </li>
                                <li>
                                    Have you used active voice and concise
                                    sentences for easier reading?
                                </li>
                            </ul>
                            <v-divider class="mx-4 mt-4" />
                            <div class="d-flex align-center mb-n2">
                                <v-checkbox disabled />
                                <h3 class="font-weight-light">Structure</h3>
                            </div>
                            <ul class="ml-2">
                                <li>
                                    Does your news or press release follow a
                                    clear structure:
                                    <ul>
                                        <li>
                                            Headline: Summarizes the key news
                                            with a clear issuer entity.
                                        </li>
                                        <li>
                                            Lead Paragraph: Answers who, what,
                                            when, where, why, and how.
                                        </li>
                                        <li>
                                            Body Paragraphs: Provide detailed
                                            context and supporting information.
                                        </li>
                                        <li>
                                            Boilerplate: Includes a brief
                                            description of your company or
                                            organization.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <v-divider class="mx-4 mt-4" />
                            <div class="d-flex align-center mb-n2">
                                <v-checkbox disabled />
                                <h3 class="font-weight-light">
                                    Prohibited Content
                                </h3>
                            </div>
                            <ul class="ml-2">
                                <li>
                                    Have you ensured your content avoids the
                                    following:
                                    <ul>
                                        <li>Illegal activities or products.</li>
                                        <li>
                                            Hate speech, discriminatory content,
                                            or defamatory statements.
                                        </li>
                                        <li>
                                            Sensationalized claims, explicit
                                            violence, or adult content.
                                        </li>
                                        <li>
                                            Political campaigning or
                                            pseudoscience.
                                        </li>
                                        <li>
                                            Private or confidential information.
                                        </li>
                                        <li>
                                            Plagiarized or copyright-infringing
                                            material
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <v-divider />
                    <div class="my-4">
                        <h3>7. On AI Use</h3>
                        <p class="mt-1">
                            The content should not be generic AI output;
                            low-quality AI content can get the brand banned from
                            MSN. You can use
                            <a
                                href="https://ampifire.com/blog/shortlyai-review/"
                                target="_blank"
                            >
                                AI for research
                            </a>
                            and ideas, but the final content should be 100%
                            fact-checked, edited for clarity and accuracy, and
                            free of typical AI exaggerations. Keep an eye out
                            for the following AI red flags known to cause
                            issues:
                        </p>
                        <ul class="ml-2 mb-2 mt-1">
                            <li>
                                AI text generators will generally exaggerate the
                                impact, importance, of relative status of the
                                subject it writes about. It may present your
                                brand as the absolute leader in your industry
                                (even though it’s not), and it may state that
                                the topic you’re writing about is of crucial
                                importance for the field (even though it may not
                                be).
                            </li>
                            <li>
                                AI will contextualize every topic as a
                                “landscape” to “navigate” or “realm” to “dive
                                into”, even though that’s inappropriate in most
                                cases (as your content will be about specific
                                topics, not about entire “landscapes” or
                                “realms”).
                            </li>
                            <li>
                                AI will present inaccurate or downright wrong
                                information as factual, and may even list
                                sources for its claims (even though said sources
                                are either misquoted or completely made up). All
                                information should be thoroughly and manually
                                checked for accuracy.
                            </li>
                        </ul>
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class MSNContentGuidelines extends Vue {}
</script>

<style lang="scss" scoped>
a {
    display: inline-flex;
}
</style>
